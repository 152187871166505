<template>
  <vx-card>
    <div class="vx-row">
      <div class="vx-col md:w-full mt-4">
        <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;"> Les champs suivis du signe (*) sont obligatoires pour l'enregistrement du prescripteur.</span>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input label="Nom & Prénoms *" v-model="NomPresc" class="w-full" />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-select autocomplete label="Centre hospitalier *" class="w-full" v-model="IdCenterHospi" >
          <vs-select-item :key="index" :value="item._id" :text="item.RaisonSoc" v-for=" (item, index ) in centreHospitaliers" />
        </vs-select>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input label="Téléphone" v-model="TelPresc" class="w-full" />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input type="email" :success="EmailPresc ? true : false" success-text="Mail valide" label="Email" v-model="EmailPresc" class="w-full" />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input label="Remarque" v-model="Remarque" class="w-full" />
      </div>
      <div class="vx-col md:w-1/2 w-full md:mt-8">
        <div class="demo-alignment">
          <span>Ristourne:</span>
          <div class="flex">
            <vs-checkbox v-model="Ristourne"></vs-checkbox>
            <vs-input-number class="ml-8" v-model="taux" label="taux:"/>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mt-base">
      <div class="vx-col text-right w-full ml-auto">
        <vs-button color="warning" type="border" class="mr-3 mb-2" @click="reset_data">Annuler</vs-button>
        <vs-button class="mb-2" :disabled="!validateForm" @click="SavePrescripteur" >Enregistrer</vs-button>
      </div>
    </div>
  </vx-card>

</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      IdCenterHospi: null,
      NomPresc: null,
      PrenomPresc: null,
      Ristourne: false,
      taux:0,
      TelPresc: null,
      Mobile: null,
      FaxPresc: null,
      AdrPresc: null,
      EmailPresc: null,
      Remarque: null
    }
  },
  computed: {
    centreHospitaliers () {
      return this.$store.state.centre_hospitalier.centre_hospitaliers
    },
    validateForm () {
      return !this.errors.any() && this.NomPresc !== null && this.IdCenterHospi !== null
    }
  },
  methods: {
    SavePrescripteur () {

      const payload = {
        IdCenterHospi:this.IdCenterHospi,
        NomPresc: this.NomPresc,
        taux: this.taux,
        Ristourne: this.Ristourne,
        TelPresc: this.TelPresc,
        EmailPresc: this.EmailPresc,
        Remarque: this.Remarque
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('prescripteur/addPrescripteur', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
          this.reset_data()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
        .finally(() => {


        })
    },
    reset_data () {
      this.IdCenterHospi = null
      this.NomPresc = null
      this.PrenomPresc = null
      this.Remarque = false
      this.TelPresc = null
      this.Mobile = null
      this.FaxPresc = null
      this.AdrPresc = null
      this.EmailPresc = null
    },
    getCentreHospitalier () {
      this.$store.dispatch('centre_hospitalier/getCentreHospitalier')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getCentreHospitalier()
  }
}
</script>
